.my-achievements {
    padding: calc(env(safe-area-inset-top) + 16px) 16px calc(env(safe-area-inset-bottom) + 16px);
}

.my-achievements-title {
    font-weight: bold;
    padding: 16px 0;
}

.my-achievements-description {
    font-size: 16px;
}

.my-achievements-badges {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 8px;
    align-items: center;
    margin: 12px 8px 0 72px;
}

.my-achievements-badge {
    text-align: center;
    border-radius: 10px;
    border: 1px solid black;
}