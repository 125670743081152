.achievement {
    padding: 8px 0;
    display: grid;
    grid-template-columns: max-content auto;
}

.achievement-chart-wrapper {
    position: relative;
    height: 60px;
    width: 60px;
}

.achievement-chart {
    height: 100%;
    width: 100%;
}

.achievement-chart-icon {
    position: absolute;
    top: 15%;
    left: 15%;
    height: 70%;
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.achievement-chart-icon svg {
    width: 24px;
    height: 24px;
}

.achievement-chart-icon .fa-heart-pulse {
    margin-top: 4px;
}

.achievement-ratio-wrapper {
    text-align: center;
}

.achievement-ratio-spinner {
    margin-top: 4px;
    font-size: 12px;
    color: #9a9a9a;
}

.achievement-ratio {
    padding-top: 2px;
    font-size: 14px;
    color: #888;
}

.achievement-badge {
    padding-top: 2px;
    font-size: 14px;
    color: #888;
}

.achievement-info {
    margin: 0 12px;
}

.achievement-title {
    font-size: 14px;
    font-weight: bold;
    margin-top: 4px;
    margin-bottom: 4px;
}

.achievement-description {
    font-size: 12px;
}

.achievement-progress-wrapper {
    position: relative;
    margin: 4px 12px 0;
    height: 12px;
}

.achievement-progress-bar-wrapper {
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #e1fad4;
    border: 1px solid #000;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    overflow: hidden;
}

.achievement-progress-bar {
    display: inline-block;
    position: absolute;
    height: 100%;
    width: 0;
    background-color: #6cb144;
}

.achievement-progress-badges {
    display: inline-block;
    position: absolute;
    top: -2px;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
}

.achievement-progress-badge {
    display: inline-block;
    position: absolute;
    height: 100%;
    width: 4px;
    border: 1px solid #000;
    border-radius: 4px;
}
