.achievements-compact {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    justify-items: center;
    padding: 16px;
}

.achievements-compact .achievement:nth-child(even) .achievement-chart-wrapper {
    margin-top: 48px;
}

.achievements-verbose {
    margin-top: 4px;
}

.achievements-verbose .achievement {
    margin: 4px 0 8px;
}
