.home-achievements-header {
    display: grid;
    grid-template-columns: max-content auto;
    grid-column-gap: 8px;
    align-items: center;
    padding: 16px 16px 0;
    font-weight: bold;
}

.home-achievements-header .fa-chevron-right {
    font-weight: normal;
    font-size: 16px;
    color: var(--color-muted2);
}
